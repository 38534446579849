import React from 'react'
import { Button } from 'reactstrap'

import styles from './button-next.module.scss'
import { ButtonNextProps } from './button-next.types'

export const ButtonNext = ({ onClick, children, fullWidth }: ButtonNextProps) => (
  <Button
    color="primary"
    className={`rounded-pill btn-wide ${fullWidth ? styles.nextButtonFullWidth : ''}`}
    onClick={onClick}
  >
    {children}
  </Button>
)
