import { useStateMachine } from 'little-state-machine'
import { useEffect } from 'react'
import { CustomerEntity } from 'src/server/customers/customer.entity'

import {
  updateCurrentCustomerAction,
  clearCurrentCustomerAction,
  updateLoggedInStatusAction
} from './actions'

export const useCustomer = () => {
  const {
    state: { currentCustomer, isLoggedIn },
    actions
  } = useStateMachine({
    updateCurrentCustomerAction,
    clearCurrentCustomerAction,
    updateLoggedInStatusAction
  })

  const updateLoggedInStatus = ({ isLoggedIn }) => {
    actions.updateLoggedInStatusAction({ isLoggedIn })
  }

  const updateCurrentCustomer = (currentCustomer: CustomerEntity) => {
    actions.updateCurrentCustomerAction(currentCustomer)
  }

  const clearCurrentCustomer = () => {
    actions.clearCurrentCustomerAction(null)
    updateLoggedInStatus({ isLoggedIn: false })
  }

  useEffect(() => {
    if (currentCustomer) {
      updateLoggedInStatus({ isLoggedIn: true })
    } else {
      updateLoggedInStatus({ isLoggedIn: false })
    }
  }, [currentCustomer])

  useEffect(() => {
    updateLoggedInStatus({ isLoggedIn: !!isLoggedIn })
  }, [isLoggedIn])

  return {
    clearCurrentCustomer,
    updateCurrentCustomer,
    updateLoggedInStatus,
    currentCustomer,
    isLoggedIn
  }
}
