export const updateGaEventAction = (globalState, payload) => ({
  ...globalState,
  gaEvent: { ...globalState.gaEvent, ...payload }
})

export const initGaEventAction = globalState => ({
  ...globalState,
  gaEvent: {}
})

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const clearGaEventAction = (globalState, payload) => {
  const newGlobalState = { ...globalState }
  delete newGlobalState.gaEvent

  return {
    ...newGlobalState
  }
}
