import { useStateMachine } from 'little-state-machine'
import { isEmpty } from 'lodash'
import { GetCustomerSessionPlanResponseDto } from 'piesotto-shared/customer-dtos/customer-session-plan-interface.dto'
import { useEffect, useMemo } from 'react'
import { fetchAuthorizedApi } from 'src/client/common/api-caller/api-caller'
import { useChosenFlavors } from 'src/client/hooks/useChosenFlavors'

import {
  updateCurrentCustomerSessionPlanAction,
  clearCurrentCustomerSessionPlanAction
} from './actions'

export const useCustomerSession = () => {
  const { state, actions } = useStateMachine({
    updateCurrentCustomerSessionPlanAction,
    clearCurrentCustomerSessionPlanAction
  })
  const { updateChosenFlavors } = useChosenFlavors()

  const customerSessionPlan = useMemo(() => state.currentCustomerSessionPlan, [state])

  const updateCustomerSessionPlan = (
    currentCustomerSessionPlan: GetCustomerSessionPlanResponseDto
  ) => {
    delete currentCustomerSessionPlan.customer
    actions.updateCurrentCustomerSessionPlanAction(currentCustomerSessionPlan)
  }

  const fetchCustomerSessionPlan = async () => {
    const customerSessionPlanPromise = await fetchAuthorizedApi('/customer-session/plan', undefined)

    try {
      const customerSessionPlanResponse = await customerSessionPlanPromise
      const customerSessionPlan = await customerSessionPlanResponse.json()
      delete customerSessionPlan.customer
      updateCustomerSessionPlan(customerSessionPlan)
    } catch (error) {
      console.debug({ error })
    }
  }

  const clearCurrentCustomerSessionPlan = () => {
    actions.clearCurrentCustomerSessionPlanAction(null)
  }

  useEffect(() => {
    if (!isEmpty(customerSessionPlan)) {
      const chosenFlavors = customerSessionPlan.dogDiets.map(dogDiet => dogDiet.dog.flavors)

      updateChosenFlavors(chosenFlavors)
    } else {
      updateChosenFlavors([])
    }
  }, [customerSessionPlan])

  return {
    clearCurrentCustomerSessionPlan,
    fetchCustomerSessionPlan,
    updateCustomerSessionPlan,
    customerSessionPlan
  }
}
