export const createReactstrapRegister = (register) => (
  name: string,
  options?: any
) => {
  const { ref, ...rest } = register(name, options)

  return {
    ...rest,
    innerRef: ref
  }
}
