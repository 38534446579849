export const updateCurrentCustomerSessionPlanAction = (globalState, payload) => ({
  ...globalState,
  currentCustomerSessionPlan: { ...payload }
})

export const clearCurrentCustomerSessionPlanAction = globalState => {
  delete globalState.currentCustomerSessionPlan

  return {
    ...globalState
  }
}
