import { useStateMachine } from 'little-state-machine'
import { useEffect, useState } from 'react'

import { setPlanModalOpenAction } from './set-plan-modal-open-action'

export const usePlanModal = () => {
  const {
    state: { modals },
    actions
  } = useStateMachine({
    setPlanModalOpenAction
  })

  const [isOpen, setIsOpen] = useState(modals?.isPlanModalOpen || false)
  

  const toggleIsPlanModalOpen = () => {
    actions.setPlanModalOpenAction(!isOpen)
  }

  const initIsPlanModalOpen = () => {
    actions.setPlanModalOpenAction(false)
  }

  useEffect(() => {
    setIsOpen(modals?.isPlanModalOpen || false)
  }, [modals?.isPlanModalOpen])

  return {
    initIsPlanModalOpen,
    isOpen,
    toggleIsPlanModalOpen
  }
}
