import { useRouter } from 'next/router'
import React from 'react'
import { Col, Container, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import { translate } from 'src/client/common/translatable/translatable'
import { useGaEvent } from 'src/client/hooks/useGaEvent'
import { usePlanModal } from 'src/client/hooks/usePlanModal'
import { GAEvent } from 'src/client/lib/ga/gtag'
import { NavbarLogo } from 'src/client/modules/plan/plan-layout/plan-navbar/navbar-logo/navbar-logo.component'
import { Routes } from 'src/client/routes'

import { ButtonNext } from '../button-next/button-next.component'

import { PLAN_MODAL_STEPS } from './plan-modal.constants'
import styles from './plan-modal.module.scss'

export const PlanModalComponent = () => {
  const router = useRouter()
  const { updateGaEvent } = useGaEvent()
  const { isOpen, toggleIsPlanModalOpen } = usePlanModal()

  const ButtonNextCaption = <span className="ctaText">{translate('Dalej')}</span>

  const handleClick = () => {
    toggleIsPlanModalOpen();
    // for GA
    const event = {
      event: GAEvent.START_PROCESS
    }

    updateGaEvent(event)
    // end - for GA

    router.push(Routes.Plan_LetsMeet)
  }

  return (
    <Modal
      isOpen={isOpen}
      size="xl"
      contentClassName={styles.modalContent}
      className={styles.modalDialog}
    >
      <ModalHeader className={styles.header}>
        <NavbarLogo noWrapper />

        <div className={styles.progressSteps}>
          {PLAN_MODAL_STEPS.map((step) => (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <img src={step.bullet} className={styles.stepImg} width={32} alt="Bullet" />

              <div className={styles.stepLabel}>{step.label}</div>
            </div>
          ))}
        </div>

        <button type="button" className={styles.closeButton} onClick={toggleIsPlanModalOpen}>
          <img src="/svgs/menu_close.svg" width={32} alt="Close" />
        </button>
      </ModalHeader>

      <ModalBody>
        <Container>
          <Row className={`${styles.bannerWrapper} mb-5`}>
            <Col xs={12} lg={6} className="mt-mobile-5-px mb-105-px">
              <div className={styles.letsMeet}>
                <div className={styles.letsMeet__header}>
                  <span>{`${translate('Opowiedz nam o')} `}</span>
                  <span className="text-primary">{translate('swoim psie')}</span>
                </div>
              </div>

              <br />
              <br />
              <br />

              <div className="font-weight-bold text-center text-lg-center  font-size-18 font-size-mobile-18 ">
                <div className="text-purple">
                  {translate('Przygotujemy dla niego plan żywienia idealnie dopasowany do jego potrzeb')}
                </div>
              </div>

              <br />
              <br />
              <br />

              <div className="font-weight-bold text-center text-lg-center  font-size-18 font-size-mobile-18 ">
                <div className={styles.textGray550}>{translate('To zajmie tylko 3 minuty')}</div>
              </div>

              <br />
              <br />

              <div className={`text-center text-lg-center ${styles.nextButtonDesktop}`}>
                <ButtonNext onClick={handleClick}>{ButtonNextCaption}</ButtonNext>
              </div>
            </Col>

            <Col xs={{ size: 12, order: 1 }} lg={{ size: 6, order: 2 }} className="text-center">
              <img
                src="/images/photos/piesotto_slider_01_podmiana.png"
                width={450}
                className={styles.image}
                alt="piesotto_piesek"
              />
            </Col>
          </Row>
        </Container>
      </ModalBody>

      <div className={`text-center text-lg-center ${styles.nextButtonMobile}`}>
        <ButtonNext onClick={handleClick} fullWidth>
          {ButtonNextCaption}
        </ButtonNext>
      </div>
    </Modal>
  )
}
