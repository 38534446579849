export const updateCurrentCustomerAction = (globalState, payload) => ({
  ...globalState,
  currentCustomer: { ...payload }
})

export const clearCurrentCustomerAction = globalState => {
  delete globalState.currentCustomer
  delete globalState.isLoggedIn

  return {
    ...globalState
  }
}

export const updateLoggedInStatusAction = (globalState, payload) => ({
  ...globalState,
  ...payload
})
