import classNames from 'classnames'
import React from 'react'
import { Button, Spinner, SpinnerProps, ButtonProps } from 'reactstrap'

import styles from './button-with-spinner.module.scss'

type ButtonWithSpinnerProps = {
  customWidth?: string
  isSpinning?: boolean
  spinnerProps?: SpinnerProps
} & ButtonProps

export const ButtonWithSpinner: React.FC<ButtonWithSpinnerProps> = ({
  children,
  customWidth,
  isSpinning,
  spinnerProps,
  ...props
}) => (
  <Button
    {...props}
    className={classNames(styles.button, props.className)}
    disabled={isSpinning || props.disabled}
    style={{ width: customWidth ?? 'auto' }}
  >
    {isSpinning && <Spinner color="light" {...spinnerProps} />}

    {!isSpinning && <>{children}</>}
  </Button>
)
