import { useStateMachine } from 'little-state-machine'
import { FoodFlavorEnum } from 'piesotto-shared/dog-dtos/food-flavor.enum'

import { updateChosenFlavorsAction } from './actions'

export const useChosenFlavors = () => {
  const {
    state: { chosenFlavors },
    actions
  } = useStateMachine({
    updateChosenFlavorsAction
  })

  const updateChosenFlavors = (flavors: FoodFlavorEnum[][]) => {
    actions.updateChosenFlavorsAction(flavors)
  }

  const clearChosenFlavors = () => {
    actions.updateChosenFlavorsAction([])
  }

  return { chosenFlavors, updateChosenFlavors, clearChosenFlavors }
}
